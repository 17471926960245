exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-505-tsx": () => import("./../../../src/pages/505.tsx" /* webpackChunkName: "component---src-pages-505-tsx" */),
  "component---src-pages-about-en-tsx": () => import("./../../../src/pages/about.en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-home-en-tsx": () => import("./../../../src/pages/home.en.tsx" /* webpackChunkName: "component---src-pages-home-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-en-tsx": () => import("./../../../src/pages/locations.en.tsx" /* webpackChunkName: "component---src-pages-locations-en-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-news-en-tsx": () => import("./../../../src/pages/news.en.tsx" /* webpackChunkName: "component---src-pages-news-en-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-reservations-en-tsx": () => import("./../../../src/pages/reservations.en.tsx" /* webpackChunkName: "component---src-pages-reservations-en-tsx" */),
  "component---src-pages-reservations-tsx": () => import("./../../../src/pages/reservations.tsx" /* webpackChunkName: "component---src-pages-reservations-tsx" */),
  "component---src-templates-beach-index-tsx": () => import("./../../../src/templates/Beach/index.tsx" /* webpackChunkName: "component---src-templates-beach-index-tsx" */),
  "component---src-templates-catering-page-index-tsx": () => import("./../../../src/templates/CateringPage/index.tsx" /* webpackChunkName: "component---src-templates-catering-page-index-tsx" */),
  "component---src-templates-event-page-index-tsx": () => import("./../../../src/templates/EventPage/index.tsx" /* webpackChunkName: "component---src-templates-event-page-index-tsx" */),
  "component---src-templates-gallery-index-tsx": () => import("./../../../src/templates/Gallery/index.tsx" /* webpackChunkName: "component---src-templates-gallery-index-tsx" */),
  "component---src-templates-giftcard-page-index-tsx": () => import("./../../../src/templates/GiftcardPage/index.tsx" /* webpackChunkName: "component---src-templates-giftcard-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-post-page-index-tsx": () => import("./../../../src/templates/PostPage/index.tsx" /* webpackChunkName: "component---src-templates-post-page-index-tsx" */),
  "component---src-templates-work-with-us-page-index-tsx": () => import("./../../../src/templates/WorkWithUsPage/index.tsx" /* webpackChunkName: "component---src-templates-work-with-us-page-index-tsx" */)
}

